import Cookies from "js-cookie"

export const getCurrency = (currency, value) => {
  if (value === 50) {
    return currency === "us" ? "$50" : currency === "au" ? "AU$50" : "£50"
  } else if (value === 500) {
    return currency === "us" ? "$500" : currency === "au" ? "AU$700" : "£500"
  } else {
    return currency === "us" ? "$250" : currency === "au" ? "AU$450" : "£250"
  }
}

const getCountry = () => {
  let country = Cookies.get("country")

  return country
}

const isBase = () => {
  let isBase = parseInt(Cookies.get("isBase"))

  if (isBase === 1 || isBase === 0) {
    return !!isBase
  } else {
    return false
  }
}

export const getUrl = url => {
  if (getCountry() === null || getCountry() === undefined) {
    return `/${url}`
  }

  return `${!isBase() ? `/${getCountry()}` : ""}/${url}`
}
