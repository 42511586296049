import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "../header/index"
import "./layout.css"

import Footer from "../footer"

const Layout = ({ children }) => {
  const [showNav, setShowNav] = React.useState(false)

  const onScroll = e => {
    if (e.target.scrollTop > 100) {
      setShowNav(true)
    } else {
      setShowNav(false)
    }
  }

  const data = useStaticQuery(graphql`
    query blogLayout {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <div onScroll={e => onScroll(e)} className={"blog"}>
        <Header
          showNav={showNav}
          siteTitle={data.site.siteMetadata?.title || `Title`}
        />
        <main>{children}</main>
        <footer>
          <Footer showNav={showNav} />
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  showNav: PropTypes.bool,
}

export default Layout
