import React from "react"
import { graphql, Link } from "gatsby"
import { getUrl } from "../components/utils.js"

// import BackgroundImage from "gatsby-background-image"
import Img from "gatsby-image"

import "./markdown.scss"

import Layout from "../components/blogLayout"
import SEO from "../components/seo"

const BlogPostTemplate = ({ data, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata?.title || `Title`
  // const { previous, next } = data

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
        metaImage={post.frontmatter.coverImage.childImageSharp?.fluid}
      />
      <article
        className="blog-post"
        itemScope
        itemType="http://schema.org/Article"
      >
        <div className={"container mx-auto  lg:px-48  pt-32"}>
          <div className={"bg-light text-primary m-5"}>
            <Img
              style={{
                maxHeight: "400px",
              }}
              fluid={post.frontmatter.coverImage.childImageSharp?.fluid}
            />
            <div className={"lg:p-12 p-6"}>
              <div className={"flex mb-5  h-full"}>
                <h1
                  className={"font-black md:text-3xl text-2xl text-primary"}
                  itemProp="headline"
                >
                  {post.frontmatter.title}
                </h1>
              </div>
              <section
                className={"markdown-body"}
                dangerouslySetInnerHTML={{ __html: post.html }}
                itemProp="articleBody"
              />
              <div className={"flex"}>
                <Link className={"ml-auto font-medium italic hover:underline"} to={getUrl(`about#${post.frontmatter.author ? post.frontmatter.author.toLowerCase().split(' ').join('-') : "alex-odwell"}`)}>
                  - {post.frontmatter.author || "The Referment Team"}
                </Link>
              </div>
            </div>
          </div>
          <div
            onClick={e => {
              window.history.back()
            }}
            style={{ width: "fit-content" }}
            className={
              "ml-auto mr-5 text-lg cursor-pointer font-medium mt-3 underline block text-secondary"
            }
          >
            Back to blog
          </div>
        </div>
      </article>
      {/* <nav className="blog-post-nav">
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </nav> */}
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        author
        date(formatString: "MMMM DD, YYYY")
        coverImage {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
